<template>
	<div class="clientList">
		<el-form :inline="true">
			<el-form-item>
				<myInput v-model="form.nickName" placeholder="输入客户名称">
					<template slot="pre">
						<span>客户姓名:</span>
					</template>
				</myInput>
			</el-form-item>
			<el-form-item>
				<!-- <el-input placeholder="输入联系电话" v-model="form.userPhone" clearable>
					<div slot="prepend">联系电话:</div>
				</el-input> -->
				<myInput v-model="form.userPhone" placeholder="输入联系电话">
					<template slot="pre">
						<span>联系电话:</span>
					</template>
				</myInput>
			</el-form-item>
			<el-form-item>
				<el-select style="height:32px" v-model="form.userStatus" clearable placeholder="客户状态">
					<el-option :value="10" label="正常"></el-option>
					<el-option :value="20" label="禁用"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-select v-model="form.userRecycle" clearable placeholder="审核状态">
					<el-option :value="1" label="待审核"></el-option>
					<el-option :value="10" label="已通过"></el-option>
					<el-option :value="20" label="已拒绝"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-date-picker v-model="time" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="changeTime"
					type="daterange"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<myButton icon title="搜索" @myclick="turnPage(1)">
					<template slot="image">
						<img src="../../assets/image/btnicon/search.png" style="width:14px;height:14px" alt="">
					</template>
				</myButton>
			</el-form-item>
			<el-form-item>
				<myButton title="新增" @myclick="gopage({},0)">
					<template slot="image">
						<img src="../../assets/image/btnicon/add.png" alt="">
					</template>
				</myButton>
			</el-form-item>
		</el-form>
		<el-table :data="tableData" :cell-style="{'text-align':'center'}"
			:header-cell-style="{'text-align':'center','background':'#F5F5F5'}">
			<el-table-column prop="userId" label="客户ID"></el-table-column>
			<el-table-column label="客户类型">
				<template slot-scope="scope">
					{{scope.row.userType == 10 ? '个人' : '企业'}}
				</template>
			</el-table-column>
			<el-table-column prop="userName" label="企业名称">
				<template slot-scope="scope">
					{{scope.row.userType == 10 ? '/' : scope.row.userName}}
				</template>
			</el-table-column>
			<el-table-column prop="nickName" label="姓名"></el-table-column>
			<el-table-column prop="userPhone" label="联系电话"></el-table-column>
			<el-table-column label="客户状态">
				<template slot-scope="{row}">
					<!-- {{scope.row.userStatus == 10 ? '启用' : '禁用'}} -->
					<div class="slot-box">
                        <pop :tips="row.userStatus == 10 ? '启用':'禁用'">
							<img class="icon" v-if="row.userStatus == 10" style="cursor: default;"  src="../../assets/image/icon/qykh.png" />
							<img class="icon" v-else style="cursor: default;"  src="../../assets/image/icon/jykh.png" />
						</pop>
					</div>

				</template>
			</el-table-column>
			<el-table-column prop="createAt" label="注册时间"></el-table-column>
			<el-table-column label="审核状态">
				<template slot-scope="scope">
					<span v-if="scope.row.userRecycle == 0">
						待完善
					</span>
					<span v-if="scope.row.userRecycle == 10" style="color: #3D7EFF;">
						通过
					</span>
					<span v-if="scope.row.userRecycle == 1">
						待审核
					</span>
					<span v-if="scope.row.userRecycle == 20" style="color: #FF3D3D;">
						拒绝
					</span>
					<!-- {{scope.row.userRecycle == 1 ? '待审核' : scope.row.userRecycle == 10 ? '通过' :'拒绝'}} -->
				</template>
			</el-table-column>
			<el-table-column label="市场信息">
				<template slot-scope="scope">
					<span v-if="scope.row.hsbEnterpriseMerchantId == 0" style="color: #FF3D3D;">
						未设置
					</span>
					<span v-else style="color: #3D7EFF;">
						已设置
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="userCharge" label="负责人">
				<template slot-scope="scope">
					<span>{{scope.row.userCharge?scope.row.userCharge:'/'}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" >
				<template slot-scope="scope">
					<!-- <div class="oper"> -->
					<div class="slot-box">
						<pop tips="详情" @myclick="$router.push({path: `/client/detail?id=${scope.row.userId}`})">
							<img class="icon"  src="../../assets/image/icon/xiangqing.png" />
						</pop>
						<pop tips="编辑">
							<img class="icon" @click="gopage(scope.row,20)" src="../../assets/image/icon/edit.png" alt="">
						</pop>
						<pop tips="禁用"  v-if="scope.row.userStatus == 10" >
							<img class="icon" @click="forbiuse(scope.row,20)" style="width:19px;height:14px" src="../../assets/image/icon/qy.png" />
						</pop>
						<pop tips="启用" v-if="scope.row.userStatus == 20">
							<img class="icon" style="width:19px;height:14px" @click="forbiuse(scope.row,10)" src="../../assets/image/icon/jy.png" />
						</pop>
						<pop tips="设置" @myclick="$router.push({path: `/client/setting?id=${scope.row.userId}`})" v-if="scope.row.userRecycle == 10">
							<img class="icon"  src="../../assets/image/icon/st.png" />
						</pop>
						<div class="slot-items">
							<pop tips="审核" v-if="scope.row.userRecycle == 1">
							<img class="icon" @click="gopage(scope.row,10)" src="../../assets/image/icon/sh.png" alt="">
						</pop>
						</div>

						<!-- <div @click="gopage(scope.row,20)" class="slots">
							<img class="icon" src="../../assets/image/icon/edit.png" alt="">
							<div class="pop">编辑</div>
						</div>
						<div @click="forbiuse(scope.row,20)" v-if="scope.row.userStatus == 10" class="slots">
							<img class="icon" style="width:19px;height:14px" src="../../assets/image/icon/qy.png"
								alt="">
							<div class="pop">启用</div>
						</div>

						<div @click="forbiuse(scope.row,10)" v-if="scope.row.userStatus == 20" class="slots">
							<img class="icon" style="width:19px;height:14px" src="../../assets/image/icon/jy.png"
								alt="">
							<div class="pop">禁用</div>
						</div>
						<div @click="gopage(scope.row,10)" v-if="scope.row.userRecycle == 1" class="slots">
							<img class="icon" src="../../assets/image/icon/sh.png" alt="">
							<div class="pop">审核</div>
						</div> -->
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="publicPage">
			<el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
				@current-change="turnPage"></el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		name: "clientList",
		data() {
			return {
				form: {
					nickName: '',
					userStatus: '',
					userRecycle: '',
					userPhone: '',
					startTime: '',
					endTime: '',
					page: 1,
					pageSize: 10
				},
				time: '',
				total: 0,
				tableData: []
			};
		},
		created() {
			//do something after creating vue instance
			this.getList();
		},
		methods: {
			getList() {
				this.$request.HttpGet('/admin/cmsUser/list', this.form).then(res => {
					if (res.data && !res.data.reload) {
						this.tableData = res.data.list || [];
						this.total = res.data.total;
					}
				});
			},
			changeTime(event) {
				if (event) {
					this.form.startTime = event[0];
					this.form.endTime = event[1];
				} else {
					this.form.startTime = '';
					this.form.endTime = '';
				}
			},
			turnPage(page) {
				this.form.page = page;
				this.getList();
			},
			gopage(item, type) {
				//0新增10审核20编辑
				this.$router.push({
					path: `/client/addClient?id=${item.userId}&type=${type}`
				});
			},
			forbiuse(data, type) {
				this.$request.HttpPost('/admin/cmsUser/status', {
						userId: data.userId,
						userStatus: type
					})
					.then(res => {
						this.getList()
					})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.oper {
		display: flex;
		align-items: center;
	}


</style>
