<template>
<div class="prolist">
   <el-form :inline="true">
      <el-form-item>
        	<myInput v-model="form.userName" placeholder="输入客户名称">
					<template slot="pre">
						<span>客户姓名:</span>
					</template>
				</myInput>
      <!-- <el-input placeholder="输入客户名称" clearable v-model="form.userName" >  <div slot="prepend">客户姓名:</div></el-input> -->
     </el-form-item>
      <el-form-item>
        <myInput v-model="form.userPhone" placeholder="输入联系电话">
					<template slot="pre">
						<span>联系电话:</span>
					</template>
				</myInput>
      <!-- <el-input placeholder="输入联系电话" clearable v-model="form.userPhone" >  <div slot="prepend">联系电话:</div></el-input> -->
     </el-form-item>
     <el-form-item >
        <myInput v-model="form.orderNo" placeholder="输入订单编号">
					<template slot="pre">
						<span>订单编号:</span>
					</template>
				</myInput>
      <!-- <el-input placeholder="输入订单编号" clearable v-model="form.orderNo" >  <div slot="prepend">订单编号:</div></el-input> -->

				<!-- <el-select v-model="form.status" clearable  placeholder="订单编号"><el-option v-for="item in client_type" :value="item.id" :key="item.id" :label="item.name"></el-option></el-select> -->
			</el-form-item>
     <el-form-item >
       <myInput v-model="form.productName" placeholder="输入产品名称">
					<template slot="pre">
						<span>产品名称:</span>
					</template>
				</myInput>
        <!-- <el-input placeholder="输入产品名称" clearable v-model="form.productName" >  <div slot="prepend">产品名称:</div></el-input> -->
				<!-- <el-select v-model="form.productName" clearable  placeholder="产品名称"><el-option v-for="item in client_type" :value="item.id" :key="item.id" :label="item.name"></el-option></el-select> -->
			</el-form-item>
      <el-form-item >
				<el-select v-model="form.orderStatus" clearable  placeholder="状态">
          <el-option  :value="10"  label="正常"></el-option>
          <el-option  :value="20"  label="关闭"></el-option>

          </el-select>
			</el-form-item>
         
      <el-form-item>
        <myButton icon title="搜索" @myclick="turnPage(1)">
           <template slot="image">
          <img src="../../assets/image/btnicon/search.png" style="width:14px;height:14px" alt="">
        </template>
        </myButton>
      </el-form-item>
          <el-form-item>
        <myButton  title="新增" @myclick="goPage('',0)">
           <template slot="image">
          <img src="../../assets/image/btnicon/add.png"  alt="">
        </template>
        </myButton>
      </el-form-item>
   </el-form>
     <el-table :data="tableData" :cell-style="{'text-align':'center'}" :header-cell-style="{'text-align':'center','background':'#F5F5F5' }">
     <el-table-column label="订单编号" prop="orderNo"></el-table-column>
     <el-table-column label="产品名称" prop="productName"></el-table-column>
     <el-table-column label="所属系统" prop="systemName"></el-table-column>
     <el-table-column label="产品价格" prop="orderPrice"></el-table-column>
     <el-table-column label="购买用户" prop="nickName"></el-table-column>
     <el-table-column label="联系电话" prop="userPhone"></el-table-column>
     <el-table-column label="状态">
       <template slot-scope="scope">
         <span  v-if="scope.row.orderStatus == 10">正常</span>
         <span  style="color:#999999" v-else-if="scope.row.orderStatus == 20">已关闭</span>
         <span  v-else>默认</span>
       </template>
     </el-table-column>
     <el-table-column label="操作">
       <template slot-scope="scope">
         <div class="slot-box">
           <pop tips="详情">
								<img class="icon" @click="goPage(scope.row,1)" src="../../assets/image/icon/xq.png" alt="">
           </pop>
           <pop tips="关闭" v-if="scope.row.orderStatus == 10">
								<img class="icon" @click="oper(20,scope.row.orderId)" style="width:19px;height:14px" src="../../assets/image/icon/qy.png" alt="">
           </pop>
           <pop tips="开启" v-if="scope.row.orderStatus == 20">
									<img class="icon" @click="oper(10,scope.row.orderId)" style="width:19px;height:14px" src="../../assets/image/icon/jy.png" alt="">
           </pop>
           <pop tips="删除">
								<img class="icon" @click="deleted(scope.row.orderId)"  src="../../assets/image/icon/sc.png" alt="" />
           </pop>
							<!-- <div  @click="goPage(scope.row,1)" class="slots" >
								<img class="icon" src="../../assets/image/icon/xq.png" alt="">
						  	<div class="pop">详情</div>
					  	</div>
              	<div  @click="oper(20,scope.row.orderId)" v-if="scope.row.orderStatus == 10" class="slots" >
								<img class="icon" style="width:19px;height:14px" src="../../assets/image/icon/qy.png" alt="">
						  	<div class="pop">开启</div>
					  	</div>

              	<div  @click="oper(10,scope.row.orderId)" v-if="scope.row.orderStatus == 20" class="slots" >
								<img class="icon" style="width:19px;height:14px" src="../../assets/image/icon/jy.png" alt="">
						  	<div class="pop">关闭</div>
					  	</div>
              <div   @click="deleted(scope.row.orderId)" class="slots" >
								<img class="icon"  src="../../assets/image/icon/sc.png" alt="">
						  	<div class="pop">删除</div>
					  	</div> -->
						</div>
       </template>
     </el-table-column>
   </el-table>
   	<div class="publicPage"><el-pagination next-text="下一页"  prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="10" @current-change="turnPage"></el-pagination></div>
</div>
</template>
<script>
import MyInput from "../../components/my-input.vue";
export default {
    data() {
        return {
            form: {
                orderNo: "",
                productName: "",
                userPhone: "",
                userName: "",
                orderStatus: ""
            },
            total: 0,
            client_type: [],
            tableData: []
        };
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            this.$request.HttpGet("/admin/cmsOrder/list", this.form)
                .then(res => {
                if (res.data && !res.data.reload) {
                    this.total = res.data.total;
                    this.tableData = res.data.list || [];
                }
            });
        },
        turnPage(e) {
            this.form.page = e;
            this.getList();
        },
        goPage(data, type) {
            this.$router.push(`productions?type=${type}&id=${data.orderId}`);
        },
        deleted(id) {
            this.$myconfirm("确认删除?", "提示")
                .then(() => {
                this.$request.HttpPost("/admin/cmsOrder/deleteByIds", { ids: [id] })
                    .then(res => {
                    this.getList();
                });
            });
        },
        oper(type, orderId) {
            this.$request.HttpPost("/admin/cmsOrder/status", { orderStatus: type, orderId })
                .then(res => {
                this.getList();
            });
        }
    },
    components: { MyInput }
}
</script>
<style lang='scss'>

</style>