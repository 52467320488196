<template>
  <div class="bannerlist">
    <myButton title="上传图片" @myclick="gopage(10,-10)" >
		<template slot="image">
			<img src="../../assets/image/icon/upload.png" >
		</template>
	</myButton>
    <div class="tablebox">
    <el-table :data="tableData" style="margin-top:15px" :cell-style="{'text-align':'center'}" :header-cell-style="{'text-align':'center','background':'#F5F5F5'}">
      <el-table-column label="名字" prop="bannerName"></el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <img
            class="tabImg"
            :src="scope.row.bannerImg"
            alt=""
            @click="previewed(scope.row.bannerImg)"
          />
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
           <div class="slot-box">
             <pop tips="启用" v-if="scope.row.bannerStatus == 10">
           			<img class="icon" style="cursor:default;" src="../../assets/image/icon/qyban.png" alt="">
             </pop>
             <pop tips="禁用" v-else>
           			<img class="icon" style="cursor:default;" src="../../assets/image/icon/jyban.png" alt="">
             </pop>
           	<!-- <div   v-if="scope.row.bannerStatus == 10" class="slots" >
           					<img class="icon" style="cursor:default;" src="../../assets/image/icon/qyban.png" alt="">
           			  	<div class="pop">启用</div>
           		  	</div>
           	
           	<div  v-else class="slots" >
           					<img class="icon" style="cursor:default;" src="../../assets/image/icon/jyban.png" alt="">
           			  	<div class="pop">禁用</div>
           		  	</div> -->
           </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
           <div class="slot-box">
             <pop tips="编辑">
								<img class="icon" @click="gopage(20,scope.row.bannerId)" src="../../assets/image/icon/edit.png" alt="">
             </pop>
             <pop tips="禁用" v-if="scope.row.bannerStatus == 10">
								<img class="icon"  @click="changeStatus(scope.row,20)" style="width:19px;height:14px" src="../../assets/image/icon/qy.png" alt="">
             </pop>
             <pop tips="启用" v-else>
								<img class="icon" style="width:19px;height:14px"  @click="changeStatus(scope.row,10)" src="../../assets/image/icon/jy.png" alt="">
             </pop>
             <pop tips="删除">
								<img class="icon" @click="deleted(scope.row)"  src="../../assets/image/icon/sc.png" alt="">
             </pop>
							<!-- <div  @click="gopage(20,scope.row.bannerId)" class="slots" >
								<img class="icon" src="../../assets/image/icon/edit.png" alt="">
						  	<div class="pop">编辑</div>
					  	</div>
              	<div  @click="changeStatus(scope.row,20)" v-if="scope.row.bannerStatus == 10" class="slots" >
								<img class="icon" style="width:19px;height:14px" src="../../assets/image/icon/qy.png" alt="">
						  	<div class="pop">启用</div>
					  	</div>

              	<div   @click="changeStatus(scope.row,10)" v-else class="slots" >
								<img class="icon" style="width:19px;height:14px" src="../../assets/image/icon/jy.png" alt="">
						  	<div class="pop">禁用</div>
					  	</div>
              <div  @click="deleted(scope.row)"  class="slots" >
								<img class="icon"  src="../../assets/image/icon/sc.png" alt="">
						  	<div class="pop">删除</div>
					  	</div> -->
						</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="publicPage">
      <el-pagination
        next-text="下一页"
        prev-text="上一页"
        layout="total, prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="turnPage"
      ></el-pagination>
    </div>
    <transition name="fade">
      <div class="preview" v-if="show" @click="show = false">
        <img :src="previewUrl" class="previewImg" alt="" />
      </div>
    </transition>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      previewUrl: "",
      show: false,
      page: 1,
      pageSize:10,
      total: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$request
        .HttpGet("/admin/cmsBanner/list", { page: this.page,pageSize:10 })
        .then((res) => {
          if(res.data && !res.data.reload){
             this.total = res.data.total;
          this.tableData = res.data.list || [];
          }
         
        });
    },
    previewed(item) {
      this.previewUrl = item;
      this.show = true;
    },
    turnPage(e) {
      this.page = e;
      this.getList();
    },
    gopage(type,id) {
      // type 10添加20编辑
      this.$router.push(`addbanner?type=${type}&id=${id}`);
    },
    deleted(item){
      this.$myconfirm('确认删除','提示')
      .then(() => {
        this.$request.HttpPost('/admin/cmsBanner/deleteByIds',{ids:[item.bannerId]})
        .then(res => {
        if(res.code == 0){
           this.$plugin.notifySuccess("删除成功")
          this.getList()
        }
        })
      })
    },
    changeStatus(item,e){
      this.$request.HttpPost('/admin/cmsBanner/status',{bannerId:item.bannerId,bannerStatus:e})
      .then(res => {
        this.getList()
      })
    }
  },
};
</script>
<style lang='scss' lang="scss">
.bannerlist {
  .tabImg {
    width: 50px;
    height: 50px;
    cursor: pointer;
    object-fit: contain;
  }
  .tablebox{
    width: 100%;
    max-height: calc(100vh - 180px);
    // overflow: auto;
  }
  .preview {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(#000000, 0.7);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    .previewImg {
      max-width: 70%;
    }
  }
  .fade-enter, .fade-leave-to{
    transform: scale(0);
  }
  .fade-enter-to, .fade-leave{
    transform: scale(1);
  }
  .fade-enter-active, .fade-leave-active{
     transition: all .2s;
  }

}

</style>