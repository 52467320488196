<template>
  <div class="prolist">
    <el-form inline>
      <el-form-item>
         <myInput v-model="form.productName" placeholder="输入产品名称">
					<template slot="pre">
						<span>产品名称:</span>
					</template>
				</myInput>
        <!-- <el-input placeholder="产品名称" v-model="form.productName" clearable>
          <div slot="prepend">产品名称:</div></el-input
        > -->
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.systemId" clearable placeholder="系统名称"
          ><el-option
            v-for="item in sysList"
            :value="item.systemId"
            :key="item.systemId"
            :label="item.systemName"
          ></el-option
        ></el-select>
      </el-form-item>
            <el-form-item>
        <myButton icon title="搜索" @myclick="turnPage(1)"  >
           <template slot="image">
          <img src="../../assets/image/btnicon/search.png" style="width:14px;height:14px" alt="">
        </template>
        </myButton>
      </el-form-item>
      <el-form-item>
        <myButton  title="新增" @myclick="gopage({},10)" >
           <template slot="image">
          <img src="../../assets/image/btnicon/add.png"  alt="">
        </template>
        </myButton>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" :cell-style="{'text-align':'center'}" :header-cell-style="{'text-align':'center','background':'#F5F5F5'}">
      <el-table-column label="产品ID" prop="productId"></el-table-column>
      <el-table-column label="产品名称" prop="productName"></el-table-column>
      <el-table-column label="金额" prop="productPrice"></el-table-column>
      <!-- <el-table-column label="产品描述">
        <template slot-scope="scope">
          <div v-html="scope.row.productText">

          </div>
        </template>
      </el-table-column> -->
      <el-table-column label="所属系统" prop="systemName"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span type="text" v-if="scope.row.productStatus == 10" style="color:#3D7EFF">上架</span>
          <span type="text" v-else-if="scope.row.productStatus == 20">下架</span>
          <span type="text" v-else>默认</span>
        </template>
      </el-table-column>
      <el-table-column label="添加时间" prop="createAt"></el-table-column>
      <el-table-column label="操作">
          <template slot-scope="scope">
              <div class="slot-box">
                <pop tips="编辑">
								<img class="icon" @click="gopage(scope.row,20)" src="../../assets/image/icon/edit.png" alt="">
                </pop>
                <pop tips="上架" v-if="scope.row.productStatus == 20">
								<img class="icon" style="width:14px;height:14px" @click="putaway(10,scope.row.productId)" src="../../assets/image/icon/sj.png" alt="">
                </pop>
                <pop tips="下架" v-if="scope.row.productStatus == 10">
								<img class="icon" style="width:14px;height:14px" @click="putaway(20,scope.row.productId)" src="../../assets/image/icon/xj.png" alt="">
                </pop>
                <pop tips="删除" >
									<img class="icon" @click="deleted(scope.row.productId)" src="../../assets/image/icon/sc.png" alt="">
                </pop>
							<!-- <div  @click="gopage(scope.row,20)" class="slots" >
								<img class="icon" src="../../assets/image/icon/edit.png" alt="">
						  	<div class="pop">编辑</div>
					  	</div>
              	<div  @click="putaway(10,scope.row.productId)" v-if="scope.row.productStatus == 20" class="slots" >
								<img class="icon" style="width:14px;height:14px" src="../../assets/image/icon/sj.png" alt="">
						  	<div class="pop">上架</div>
					  	</div>

              	<div  v-if="scope.row.productStatus == 10" @click="putaway(20,scope.row.productId)" class="slots" >
								<img class="icon" style="width:14px;height:14px" src="../../assets/image/icon/xj.png" alt="">
						  	<div class="pop">下架</div>
					  	</div>
              <div   @click="deleted(scope.row.productId)" class="slots" >
								<img class="icon"  src="../../assets/image/icon/sc.png" alt="">
						  	<div class="pop">删除</div>
					  	</div> -->
						</div>
              <!-- <el-button type="text" @click="gopage(scope.row,20)">编辑</el-button>
              <el-button type="text" v-if="scope.row.productStatus == 10" @click="putaway(20,scope.row.productId)">下架</el-button>
            <el-button type="text" v-else-if="scope.row.productStatus == 20" @click="putaway(10,scope.row.productId)">上架</el-button>
              <el-button type="text" @click="deleted(scope.row.productId)">删除</el-button> -->
          </template>
      </el-table-column>
    </el-table>
   	<div class="publicPage"><el-pagination next-text="下一页"  prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="10" @current-change="turnPage"></el-pagination></div>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
        form:{
          productName:'',
          systemId:'',
          page:1,
          pageSize:10
        },
        sysList:[],
        tableData:[{id:1}],
        total:0
    };
  },
  created(){
     this.gtetList();
     this.getSystemList()
  },
  methods:{
      gtetList(){
        this.$request.HttpGet('/admin/cmsProduct/list',this.form)
        .then(res => {
          if(res.data && !res.data.reload){
             this.tableData = res.data.list || [];
            this.total = res.data.total;
          }
         
        })
      },
      getSystemList(){
       this.$request.HttpGet('/admin/cmsSystem/list')
       .then(res => {
         this.sysList = res.data.list
         console.log(res)
       })
      },
      gopage(data,type){
         this.$router.push(`addpro?type=${type}&id=${data.productId}`)
      },
      putaway(e,id){
        this.$request.HttpPost('/admin/cmsProduct/status',{productStatus:e,productId:id})
        .then(res => {
          this.gtetList()
        })
      },
      deleted(id){
         this.$myconfirm('确认删除?','提示')
         .then(() =>{
           this.$request.HttpPost('/admin/cmsProduct/deleteByIds',{ids:[id]})
           .then(res => {
             this.$plugin.notifySuccess("删除成功")
             this.gtetList()
           })
         })
      },
      turnPage(e){
          this.form.page = e;
          this.gtetList()
      }
  }
};
</script>
<style lang='scss' scoped>
 
</style>